<template>

  <v-container class="wrapper elevation-2" fluid>
    <div v-if="showExtraMenu" class="menuwrapper">
      <v-menu class="float-right">
        <template v-slot:activator="{ on }">
          <v-btn icon color="primary" dark v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(extraVar,idx) in extraVariables" :key="idx">
            <v-list-item-title class="mx-0" @click="onExtraSelected(extraVar)">{{extraVar.title}}</v-list-item-title>
          </v-list-item>
        </v-list>

      </v-menu>
    </div>



    <v-row>
      <v-col class="pb-0">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="title ">
              {{currentVariable.title}}
            </div>
          </template>
          <span>{{currentVariable.helpText}}</span>
        </v-tooltip>

      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div class="score" :style="{ color: titleColor }">
          {{currentVariable.formattedValue}}
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-0" :style="{ 'visibility': progressBarVisibility }">
      <v-col class="py-0">
        <AnimatedProgressBarComponent :color="titleColor" :value="valuePercentage" :target="goalPercentage" />
      </v-col>
    </v-row>


    <v-row align="center mb-0">
      <v-col cols="6" >

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <div v-on="on" class="iconnumberleft">

                <template v-if="showBase">
                    <v-icon  small class="bottomicon, mr-1" >mdi-message-outline</v-icon>
                    <span class="bottomNumbers">{{currentVariable.formattedBase}}</span>
                </template>
                <template v-else>
                    <v-icon large class="bottomicon" :color="arrowColor">{{ arrowIcon }}</v-icon>
                    <span class="bottomNumbers">{{currentVariable.formattedDiff}}</span>
                </template>
            </div>
          </template>
          <span>{{'DIFF_TOOLTIP' | i18n}}</span>
        </v-tooltip>

      </v-col>

      <v-col cols="6">
        <div class="text-right" v-if="!vardto.hideProgressBar">

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <div v-on="on" class="iconnumberright">
                <v-icon small class="bottomicon mr-3">mdi-crosshairs-gps</v-icon>
                <span class="bottomNumbers">{{currentVariable.formattedGoal}}</span>
              </div>
            </template>
            <span>{{'GOAL_TOOLTIP' | i18n}}</span>
          </v-tooltip>

        </div>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
import AnimatedProgressBarComponent from './AnimatedProgressBarComponent'

export default {
  name: 'VariableComponent',

  components: { AnimatedProgressBarComponent },

  props: {
    vardto: Object
  },

  data: () => ({
    selectedVariable: null
  }),

  computed: {
    showBase() {
      return this.vardto.formattedBase!==null
    },

    extraVariables() {
      return [this.vardto, ...this.vardto.extraVariables]
    },

    currentVariable() {
      return this.selectedVariable ? this.selectedVariable : this.vardto
    },

    showExtraMenu() {
      return this.vardto.extraVariables.length > 0
    },

    arrowIcon: function () {
      if (this.currentVariable.diffArrow === 1) {
        return 'mdi-menu-up'
      } else if (this.currentVariable.diffArrow === 2) {
        return 'mdi-menu-down'
      }

      return 'mdi-trending-neutral'
    },

    arrowColor: function () {
      if(this.currentVariable.inverse) {
        if (this.currentVariable.diffArrow === 1) {
          return '#b9133e'
        } else if (this.currentVariable.diffArrow === 2) {
          return '#00c896'
        }
      } else {
        return (this.currentVariable.diffArrow === 1) ? '#00c896' : '#b9133e';
      }

      return '#000000'
    },

    valuePercentage: function () {
      let minMaxSpan = this.vardto.maxValue - this.vardto.minValue
      var fracValue = (this.vardto.value == null) ? null : this.vardto.value / minMaxSpan

      if(fracValue && fracValue>1.0) {
        fracValue = 1.0;
      }
      return fracValue * 100
    },
    goalPercentage: function () {
      let minMaxSpan = this.vardto.maxValue - this.vardto.minValue
      let fracGoal = (this.vardto.goalValue == null) ? null : this.vardto.goalValue / minMaxSpan
      return fracGoal * 100
    },

    titleColor: function () {
      if(this.currentVariable.goalValue !== null) {
        if(this.currentVariable.inverse) {
          return (this.currentVariable.value >= this.currentVariable.goalValue) ? '#b9133e' : '#00c896'
        } else {
          return (this.currentVariable.value >= this.currentVariable.goalValue) ? '#00c896' : '#b9133e'
        }
      }

      return '#000000'
    },

    progressBarVisibility: function () {
      return this.vardto.hideProgressBar ? 'hidden' : 'visible'
    }

  },

  methods: {
    onExtraSelected(extraVar) {
      this.selectedVariable = extraVar
    }
  }



}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
  height: 100%;
  background-color: white;
  border-radius: 5px;
}

.menuwrapper {
  text-align: end;
  float: right;
}

div.title {
  /*font-size: 20px !important;*/
  font-size: calc((20/16)*1rem) !important;

  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  line-height: 20px;
  height: 80px;
  padding-left: 10px;
  padding-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
}

div.score {
  padding-left: 10px;
  padding-right: 10px;
  /*font-family: "Verdana", sans-serif !important;*/
  /*font-family: "Roboto", sans-serif !important;*/
  font-family: "Roboto", sans-serif !important;
  font-size: calc((50/16)*1rem);
  /*font-size: 50px;*/
  font-weight: 900;
}

i.bottomicon {
  vertical-align: text-top;
}

span.bottomNumbers {
  font-family: "Roboto", sans-serif !important;
  font-weight: 900;
  font-size: calc((16/16)*1rem);
  /*font-size: 16px;*/
}

div.iconnumberleft {
  display: flex;
  align-items: center;
}

div.iconnumberright {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 8px;
}

</style>
