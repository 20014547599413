<template>
  <v-container fluid pa-3>
    <ConfirmDialog ref="confirm" />

    <v-alert type="error" v-if="errorMessage">
      {{errorMessage}}
    </v-alert>


    <v-form v-if="templateDetails!==null"  ref="form" >
      <v-row class="pa-3">
        <v-col>
          <h4>{{headlineLabel}}</h4>
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col>
          <v-text-field :disabled="!isNew" v-model="templateDetails.name" :label="$t('NAME')" />
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col>
          <v-text-field v-model="templateDetails.subject" :label="$t('MAIL_SUBJECT')" />
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col cols="6">
          <v-text-field required v-model="templateDetails.fromName" :rules="nameRules" :label="$t('MAIL_SENDER_NAME')" />
        </v-col>
        <v-col cols="6">
<!--          <v-text-field v-model="templateDetails.fromEmail" :rules="emailRules" :label="$t('MAIL_SENDER_EMAIL')" />-->
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col>
          <v-text-field disabled v-model="templateDetails.labels" :label="$t('MAIL_LABELS')" />
        </v-col>
      </v-row>

      <v-row class="pa-3">
        <v-col>
          <div class="mt-4">
            <v-btn class="mr-4" @click="onSubmitClicked">{{ buttonLabel }}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode && !isNew" @click="onDeleteClicked">{{'DELETE' | i18n}}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode && !isNew && this.templateDetails.design" @click="onDuplicateClicked">{{'DUPLICATE' | i18n}}</v-btn>
            <v-btn class="mr-4" v-if="!embeddedMode" @click="onCancelClicked">{{'CANCEL' | i18n}}</v-btn>

            <v-dialog
                v-model="dialog"
                width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on">Test mail</v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Send test mail
                </v-card-title>

                <v-card-text>
                  <v-text-field v-model.lazy.trim="testmail" label="email" type="text" required/>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="onSendTestMail();dialog = false" :disabled="!testmail || testmail.indexOf('@')===-1">{{'SEND' | i18n }}</v-btn>
                  <v-btn color="primary" text @click="dialog = false">{{'CANCEL' | i18n}}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>



            <!--            <v-btn class="mr-4">Test mail</v-btn>-->
          </div>
        </v-col>
      </v-row>



      <v-row v-if="emailEditorOptions">
        <v-col>
          <EmailEditor class="unlayer"
                       :project-id="emailEditorOptions.projectId"
                       ref="emailEditor"
                       min-height="700px"
                       :options="emailEditorOptions"
                       v-on:load="editorLoaded" />
        </v-col>
      </v-row>


    </v-form>

  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import PageNavigator from "../../pagenavigator";
import MailsService from "@/services/MailsService";
// import ConfirmDialog from "@/components/ConfirmDialog";
// import AceEditor from "vuejs-ace-editor";
import { EmailEditor } from 'vue-email-editor'
import ConfirmDialog from "@/components/ConfirmDialog";
export default {
  name: 'MailTemplateDetails',

  components: {
    ConfirmDialog, EmailEditor
  },

  props: {
    embedded: Boolean,
    templateName: null
  },

  data: () => ({
    templateDetails: null,
    repeatPassword: '',

    rolesList: [],

    isNew: false,

    nameRules: [
      v => !!v || 'Name is required'
    ],

    emailRules: [ v => /.+@.+/.test(v) || 'Invalid Email address' ],

    emailEditorOptions: null,

    dialog: false,
    testmail: "",

    errorMessage: null,

  }),

  created: function () {
    if(this.templateName) {
      this.isNew = false;

      MailsService.fetchMailTemplateData(this.getAuthToken, this.activeSurvey.extName, this.templateName).then((response) => {
        this.templateDetails = response.data
      })

    } else {
      this.isNew = this.$route.path.endsWith("create");

      if(this.isNew) {
        this.templateDetails = { name: "", subject: "", labels: "", code: "", fromName: "", fromEmail: ""}
      } else {
        MailsService.fetchMailTemplateData(this.getAuthToken, this.activeSurvey.extName, this.$route.params.name).then((response) => {
          this.templateDetails = response.data
        })
      }

    }


    MailsService.fetchUnlayerEditorOptions(this.getAuthToken, this.activeSurvey.extName).then((response) => {
      // console.log(response.data)
      this.emailEditorOptions = response.data
    })


  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    buttonLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    headlineLabel() {
      return this.isNew ? this.$t('CREATE') : this.$t('UPDATE')
    },

    embeddedMode() {
      return this.templateName
    }

  },


  methods: {

    onSendTestMail() {
      this.$refs.emailEditor.editor.exportHtml( (data) => {
        this.templateDetails.code = data.html;
        this.templateDetails.design = JSON.stringify(data.design);

        if(this.isNew) {
          MailsService.createMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails, this.testmail).then((response) => {
            this.templateDetails = response.data
          })
        } else {
          MailsService.updateMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails, this.testmail).then(() => {
          })
        }
      })

      // console.log("Sending!!")
    },

    goToList() {
      const fromPage = this.$route.query.fromPage

      if(fromPage) {
        PageNavigator.navigateToPage(this, fromPage, this.activeSurvey.extName,  this.getFilteredDrillPath)
      } else {
        PageNavigator.navigateToDefaultPage(this, this.activeSurvey)
      }
    },

    onCancelClicked() {
      this.goToList()
    },

    onSubmitClicked() {
      if (this.$refs.form.validate()) {

        this.$refs.emailEditor.editor.exportHtml( (data) => {
          this.templateDetails.code = data.html;
          this.templateDetails.design = JSON.stringify(data.design);

          if (this.isNew) {
            MailsService.createMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then(() => {
              this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
              if(!this.embedded) {
                this.goToList()
              }
            }).catch((/*error*/) => {
              this.errorMessage = "Failed creating Mandrill template - name already in use"
            })

          } else {
            MailsService.updateMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails).then(() => {
              this.$store.commit('setSnackText', this.$t('CHANGES_SAVED'))
              if(!this.embedded) {
                this.goToList()
              }
            })
          }
        })

      }
    },

    async onDuplicateClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Duplicate template?")) {
        this.isNew = true
        this.templateDetails.name = ""
      }
    },

    async onDeleteClicked() {
      if ( await this.$refs.confirm.open("Confirm", "Delete template?")) {
        MailsService.deleteMailTemplate(this.getAuthToken, this.activeSurvey.extName, this.templateDetails.slug).then(() => {
          this.goToList()
        })
      }
    },


    // https://docs.unlayer.com/reference/authentication
    editorLoaded() {

      const authToken = this.getAuthToken
      const survey = this.activeSurvey.extName

      const editor = this.$refs.emailEditor.editor

      editor.registerCallback('image', function(file, done) {
        MailsService.uploadUnlayerFile(authToken, survey, file).then((response) => {
          done({ progress: 100, url: response.data })
        })
      });



      editor.registerCallback('image:removed', function (image, done) {
        // image will include id, userId and projectId
        MailsService.deleteUnlayerFile(authToken, survey, image.id).then(() => {
          // call this when image has been deleted
          done();
        });
      });



      MailsService.fetchUnlayerImages(this.getAuthToken, this.activeSurvey.extName).then((response) => {
        const images = response.data;

        editor.registerProvider('userUploads', function (params, done) {
          // console.log("images")
          // console.log(images)
          done(images);
        });
      });


      if(this.isNew) {
//        this.$refs.emailEditor.editor.loadDesign( {} );
      } else {
        if(this.templateDetails.design) {
          this.$refs.emailEditor.editor.loadDesign( JSON.parse( this.templateDetails.design ) );
        } else {
          this.$refs.emailEditor.editor.loadDesign( { html: this.templateDetails.code, classic: true });
        }
      }

    },

    updateModelWithUnlayerEditor() {

      this.$refs.emailEditor.editor.exportHtml(
          (data) => {
            this.templateDetails.code = data.html;
            this.templateDetails.design = JSON.stringify(data.design);
          }
      )


    },



    saveDesign() {
      this.$refs.emailEditor.editor.saveDesign(
          (design) => {
            this.templateDetails.design = JSON.stringify(design);

          }
      )
    }

  },


}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.unlayer {
  height: 100%;
}


</style>
