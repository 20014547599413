import Vue from 'vue'
import Vuex from 'vuex'
import AuthService from '@/services/AuthService'
import FiltersService from "./services/FiltersService";
import CombinedFilters from '@/filters.js'

import _ from 'lodash'
import InterviewsService from "./services/InterviewsService";
import SamplesService from "./services/SamplesService";
import ReportsService from "./services/ReportsService";
import AnalyticsService from "./services/AnalyticsService";
import Clientconfig from "@/clientconfig";
import { set } from 'vue-analytics'
import DebugService from "@/services/DebugService";
import SurveysService from "@/services/SurveysService";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        /** @type UserIdentity */
        userIdentity: null,

        keyCloak: null,

        activeSurvey: null,

        activeView: null,

        /** @type string */
        activeDrillPath: null,

        combinedFilters: null,

        filtersMap: null,   // map of filterset

        rankingList: null,

        interviewData: null,

        // alertList: null,

        sampleList: null,

        segmentedDrillPath: null,

        filterSideBar: false,

        searchTerm: null,
        searchSuggestions: [],
        filterTerm: null,   // results from tables are filtered with this

        timeFilterTab: 0,

        snackText: null,

        textAnalyticsData: null,

        numberAlerts: 0,
        numberOpenAlerts: 0,

        downloadStatus: {
            state: ReportsService.downloadStateEnum.none,
            surveyExtName: null,
            reportUUID: null, /* the task ID of the download */
            positionInQueue: null /* the postion in report generation download queue */
        },
        snackbar: {
            show: false,
            text: '',
        },

        globalEvent: null,


    },

    mutations: {

        keycloak(state, keyCloak) {
            state.keyCloak = keyCloak
        },

        login (state, userid) {
            state.userIdentity = userid // userid is always stored!

            if(state.userIdentity.loginStatus === 0 /* state.surveys && state.surveys.length > 0*/) {
                state.activeSurvey = userid.surveys[0]
                state.activeView = state.activeSurvey.views[0]
                state.activeDrillPath = state.activeView.startPath
                state.combinedFilters = new CombinedFilters(state.activeSurvey.startFilter)
//                console.log("*************** Just set start filter to: " + state.activeSurvey.startFilter)
                state.filtersMap = new Map()

                // tracking
                set("universe", userid.universe )
            }


        },

        logout (state) {
            state.userIdentity = null
            state.activeSurvey = null
            state.activeView = null
            state.activeDrillPath = null
            state.combinedFilters = null
            state.filtersMap = null
            state.rankingList = null
            state.interviewData = null
            // state.alertList = null
            state.sampleList = null
            state.segmentedDrillPath = null
            state.filterSideBar = false
            state.searchTerm = null
            state.searchSuggestions = []
            state.filterTerm = null
            state.snackText = null
            state.downloadStatus = {
                'state': ReportsService.downloadStateEnum.none,
                surveyExtName: null,
                reportUUID: null,
                positionInQueue: null
            }
        },

        showSnackbar(state, text) {
            let timeout = 0;
            if (state.snackbar.show) {
                state.snackbar.show = false;
                timeout = 300; // ms
            }
            setTimeout(() => {
                state.snackbar.show = true;
                state.snackbar.text = text;
            }, timeout)
        },
        hideSnackbar(state) {
            state.snackbar.show = false;
        },


        rankinglist (state, dto) {
            state.rankingList = dto
        },

        interviewData (state, dto) {
            state.interviewData = dto
        },

        loadCount (state, loadCountDTO) {
            for (const [surveyExtName, value] of Object.entries(loadCountDTO)) {
                const survey = _.find(state.userIdentity.surveys, { 'extName': surveyExtName})
                if(survey) {
                    survey.loadCount = value;
                }
            }

        },

        samplelist (state, dto) {
            state.sampleList = dto
        },

        selectSurvey (state, surveyExtName) {
            state.activeSurvey = _.find(state.userIdentity.surveys, { 'extName': surveyExtName})
//            console.log('Selected survey: ' + state.activeSurvey.extName + ' with title: ' + state.activeSurvey.title)
            state.activeView = state.activeSurvey.views[0]
            state.activeDrillPath = state.activeView.startPath
            state.combinedFilters = new CombinedFilters(state.activeSurvey.startFilter)

            // Report download
            state.downloadStatus = {
                'state': ReportsService.downloadStateEnum.none,
                surveyExtName: null,
                reportUUID: null,
                positionInQueue: null
            }

            // tracking
            set("metainterview", surveyExtName )

            // Consider added an event that survey / metainterview changed

        },

        setAlertFilter(state) {
            state.combinedFilters = new CombinedFilters(state.activeSurvey.alertFilter)
        },

        removeCampaign(state) {
            if(state.activeSurvey.campaigns.length>0) {
                state.activeSurvey.campaigns.shift()
            }
        },

        setNumberOpenAlerts(state, nOpenAlerts) {
            state.numberOpenAlerts = nOpenAlerts;
        },

        setNumberAlerts(state, nAlerts) {
            state.numberAlerts = nAlerts;
        },

        selectView (state, viewExtName) {
            let view = _.find(state.activeSurvey.views, { 'extName': viewExtName})
            if( typeof view !== 'undefined') {
                state.activeView = view
                state.activeDrillPath = view.startPath
//                console.log('Selected view: ' + state.activeView.extName + " with title " + state.activeView.title)

                // tracking
                set("view",  viewExtName)
            }

        },

        setDrillPath (state, drillpath) {
            // console.log(`Setting drill path:  ${drillpath}`)
            state.activeDrillPath = drillpath
        },

        moveDrillPathUp(state) {
            //            1866,fhdlr,956
            let pathSegments = _.split(state.activeDrillPath, ",")

            if(pathSegments.length>2) {
                pathSegments = _.slice(pathSegments, 0, pathSegments.length-1)
                state.activeDrillPath = _.join(pathSegments, ',');
            }
        },


        setFilteredDrillPath (state, filteredDrillPath) {
//            console.log("*************************************'setFilteredDrillPath: " + filteredDrillPath)
            let parts = filteredDrillPath.split(';')
            if (parts.length > 0) {
                state.activeDrillPath = parts[0]

                const pathSplit = parts[0].split(',')

                const views = (state.activeSurvey !==null) ? state.activeSurvey.views : []

                const view = _.find(views, {extName : pathSplit[1]})

                state.activeView = view

                for (var i = 1; i < parts.length; i++) {
                    state.combinedFilters.setFilters(parts[i])
                }
            }
        },


        viewSelected(viewExtName) {
            this.$store.commit('selectView', viewExtName)
        },

        setFilter (state, { filterName, filterValue }) {
            state.combinedFilters.setFilter(filterName, filterValue)
        },

        clearFilter (state, { filterName, filterValue }) {
            state.combinedFilters.clearFilter(filterName, filterValue)
        },

        resetFilters (state) {
            state.combinedFilters = new CombinedFilters(state.activeSurvey.startFilter)
        },

        setFilterSet (state, { surveyExtName, filterSet}) {
            const survey = _.find(state.userIdentity.surveys, { 'extName': surveyExtName})
            Vue.set(survey, 'standardFilterSet', filterSet)
        },

        setTimeFilterSet (state, { surveyExtName, filterSet}) {
            const survey = _.find(state.userIdentity.surveys, { 'extName': surveyExtName})
            Vue.set(survey, 'timeFilterSet', filterSet)
        },

        addCustomTimeFilter(state, { title, extName}) {
            state.activeSurvey.timeFilterSet.filterGroups[0].filters[0].options.push( { extName: extName, title: title } )
        },

        setSegmentedDrillPath (state, sdp) {
            state.segmentedDrillPath = sdp
        },

        toggleFilterSideBarState (state) {
            state.filterSideBar = !state.filterSideBar
        },

        setFilterSideBarState (state, showSidebar) {
            state.filterSideBar = showSidebar
        },

        setTimeFilterTabActive (state) {
            state.timeFilterTab++
        },

        setSearchTerm (state, searchTerm) {
            state.searchTerm = searchTerm
        },

        setSearchSuggestions (state, suggestions) {
            state.searchSuggestions = suggestions
        },

        setBugs (state, { surveyName, bugList} ) {
            const survey = _.find(state.userIdentity.surveys, { 'extName': surveyName})
            Vue.set(survey, 'bugs', bugList)
        },

        setFilterTerm (state, filterTerm) {
            state.filterTerm = filterTerm
        },

        setSnackText (state, snackText) {
            state.snackText = snackText
        },

        setTextAnalyticsData (state, data) {
            state.textAnalyticsData = data
        },

        setDownloadStatus(state, { downloadState, surveyExtName, reportUUID, positionInQueue}) {
            state.downloadStatus.state = downloadState
            state.downloadStatus.surveyExtName = surveyExtName
            state.downloadStatus.reportUUID = reportUUID
            state.downloadStatus.positionInQueue = positionInQueue
        },

        setDownloadState( state, { downloadState }) {
            state.downloadStatus.state = downloadState
        },

        setGlobalEvent(state, { source, type, data } ) {
            state.globalEvent = { source, type, data };
        }

    },

    actions: {
        postGlobalEvent(context, { source, type, data }) {
            context.commit("setGlobalEvent", { source, type, data })
        },

        authenticateUserPassword (context, { username, password, remember, universe, surveyName, actAsUser, actAsUniverse }) {

            AuthService.authenticateUserPassword(username, password, remember, universe, surveyName, actAsUser, actAsUniverse).then((response) => {
                context.commit('login', response.data)

                if(remember) {
                    this._vm.$cookies.set("sessionId", response.data.authToken )
                }
            })

        },

        authenticateCookie (context, sessionId) {
            AuthService.authenticateCookie(sessionId).then((response) => {
                context.commit('login', response.data)
            })
        },

        changeUniverse (context, universe) {
            AuthService.changeUniverse(context.getters.getAuthToken, universe).then((response) => {
                context.commit('logout')
                context.commit('login', response.data)
            })
        },

        updateLoadCount ( context ) {
            SurveysService.fetchLoadCount(context.getters.getAuthToken, context.getters.getActiveSurvey.extName).then((response) => {
                // console.log("Response from samplelist")
                context.commit('loadCount', response.data)
            })
        },

        fetchInterviewData ( context, interviewId) {
            context.commit('interviewData', null)
            if( context.getters.getActiveSurvey !== null) {
                InterviewsService.fetchInterviewData(context.getters.getAuthToken, context.getters.getActiveSurvey.extName, interviewId).then((response) => {
                    // console.log("Response from interviewsservice")
                    context.commit('interviewData', response.data)
                })
            }
        },

        fetchSampleListData ( context ) {
            if( context.getters.getActiveSurvey !== null) {
                SamplesService.fetchSamplesListData(context.getters.getAuthToken, context.getters.getActiveSurvey.extName, "", "lsegs=1" ).then((response) => {
                    // console.log("Response from samplelist")
                    context.commit('samplelist', response.data)
                })
            }
        },


        fetchFilterData ( context, { surveyName, filtersType }) {
            FiltersService.fetchFilterData(context.getters.getAuthToken, surveyName, filtersType ).then((response) => {
                if(filtersType==="standard") {
                    context.commit('setFilterSet', { surveyExtName: surveyName, filterSet: response.data})
                } else if(filtersType==="time") {
                    context.commit('setTimeFilterSet', { surveyExtName: surveyName, filterSet: response.data})
                }
            })
        },

        registerDownloadInitiated( context, { surveyName, reportUUID}) {
            context.commit("setDownloadStatus", {downloadState: ReportsService.downloadStateEnum.downloadQueued, surveyName, reportUUID, positionInQueue: null})
        },
        registerDownloadReady(context, {surveyName, reportUUID}) {
            context.commit("setDownloadStatus", {downloadState: ReportsService.downloadStateEnum.downloadReady, surveyName, reportUUID, positionInQueue: null})
        },
        registerDownloadComplete(context) {
            context.commit("setDownloadState", {downloadState: ReportsService.downloadStateEnum.downloadCompleted})
        },
        registerDownloadStatus(context, { surveyName, reportUUID, position}) {
            context.commit("setDownloadStatus", { downloadState: ReportsService.downloadStateEnum.downloadQueued, surveyName, reportUUID, positionInQueue: position})
        },

        changeSurvey( context, { srcComponent, surveyExtName}) {
            context.commit('selectSurvey', surveyExtName)
            const survey = _.find(context.state.userIdentity.surveys, { 'extName': surveyExtName})
            AnalyticsService.trackChangeSurveyEvent(srcComponent, survey)

            context.dispatch('fetchBugsForSurvey', surveyExtName)
        },

        fetchBugsForSurvey(context, surveyname) {
            if( context.getters.getActiveSurvey !== null) {
                DebugService.fetchBugs(context.getters.getAuthToken, surveyname).then((response) => {
                    context.commit('setBugs', {surveyName: surveyname, bugList: response.data})
                })
            }
        },

        clearBugsForSurvey(context, surveyname) {
            if( context.getters.getActiveSurvey !== null) {
                DebugService.clearBugs(context.getters.getAuthToken, surveyname).then((/*response*/) => {})
                context.commit('setBugs', {surveyName: surveyname, bugList: []})
            }
        }




    },

    getters: {
        isAuthenticated: state => {
            if(Clientconfig.isLoaded()) {
                if(Clientconfig.isKeyCloakMode()) {
                    return state.userIdentity!=null && state.keyCloak!=null && state.keyCloak.authenticated
                } else {
                    return state.userIdentity!=null // ??
                }
            }

            return false
        },

        getAuthToken: state => {
            if(Clientconfig.isLoaded()) {
                if (Clientconfig.isKeyCloakMode()) {
                    return state.keyCloak.token
                } else {
                    return state.userIdentity.authToken
                }
            }
        },

        isLoggedIn: state => {
            return (state.userIdentity != null && state.activeSurvey != null)
        },

        getActiveSurvey: state => { return state.activeSurvey },

        getLoadCount: state => { return state.activeSurvey ? state.activeSurvey.loadCount : 0 },

        getMailCampaignIds: state => {
            return state.combinedFilters.getFilterValuesForFilter("mcids");
        },

        getFilteredDrillPath: state => {
            return state.activeSurvey ? state.activeDrillPath + ';' + state.combinedFilters.asTokenString() : ""
        },

        getDefaultFilteredDrillPath: state => {
            return state.activeSurvey ? state.activeDrillPath + ';' + new CombinedFilters(state.activeSurvey.startFilter).asTokenString() : ""
        },

        getAlertFilteredDrillPath: state => {
            return state.activeSurvey ? state.activeDrillPath + ';' + new CombinedFilters(state.activeSurvey.alertFilter).asTokenString() : ""
        },

        isAlertFilterDefined: state => {
            return state.activeSurvey ? state.activeSurvey.alertFilter : false
        },

        getCompatibleFilteredDrillPath: state => {
            return state.activeSurvey ? state.activeDrillPath + '&' + state.combinedFilters.asTokenString() : ""
        },

        getTimeFilter: state => {
            const ftimelist = state.combinedFilters.getFilterValuesForFilter("ftime")
            return ftimelist && ftimelist.length>0 ? ftimelist[0] : ""
        },

        getFilterSelectedOptions: state => filterName => {
            const options = state.combinedFilters.getFilterValuesForFilter(filterName)
            return options ? options : []
        },


        getLastDrillPathSegmentName: state => {
            if(state.segmentedDrillPath!=null && state.segmentedDrillPath.segments.length>0) {
                const lastSegment = state.segmentedDrillPath.segments[state.segmentedDrillPath.segments.length-1]
                return lastSegment.path.name
            } else {
                return ""
            }
        },

        getSurveys: state => {
            return (state.userIdentity != null) ? state.userIdentity.surveys : []
        },

        getViews: state => {
            if(state.activeSurvey !==null ) {
                return state.activeSurvey.views
            }
            return []
        },

        getStandardFilters: state => {
            if(state.activeSurvey !== null ) {
                // console.log("Looking up:" + state.activeSurvey.extName + "standard")
                // console.log( state.filtersMap.get( state.activeSurvey.extName + "standard" ) )
//                return state.filtersMap.get( state.activeSurvey.extName + "standard" )
                return state.activeSurvey.standardFilterSet
                // filtersMap.get( state.activeSurvey.extName + "standard" )
            }
        },

        getFilterSet: state => filterType => {
            if(state.activeSurvey !== null ) {
                return state.filtersMap.get( state.activeSurvey.extName + filterType )
            }
        },

        getPageById: (state) => (pageId) => {
            if (state.activeSurvey != null)  {
                return _.find(state.activeSurvey.pages, { 'extname': pageId})
            }
            return null;
        },

        getCampaigns: (state) => {
            if (state.activeSurvey != null)  {
                return state.activeSurvey.campaigns
            }
            return [];
        },


        getGamificationPageId: (state) => {
            const pages = state.activeSurvey != null ? state.activeSurvey.pages : [];

            for (const page of pages) {
                for (const component of page.components) {
                    if(component.type==='GamificationOverviewConfig') {
                        return page.extname
                    }
                }
            }

            return null
        },

        getRankingPageId: (state) => {
            const pages = state.activeSurvey != null ? state.activeSurvey.pages : [];

            for (const page of pages) {
                for (const component of page.components) {
                    if(component.type==='RankingTableComponent') {
                        return page.extname
                    }
                }
            }

            return "rankings" // fallback to old rankings
        },

        getMailCampaignOverviewPageId: (state) => {
            const pages = state.activeSurvey != null ? state.activeSurvey.pages : [];

            for (const page of pages) {
                for (const component of page.components) {
                    if(component.type==='MailCampaignsListComponent') {
                        return page.extname
                    }
                }
            }

            return pages.extname // fallback to first page
        },

        getMailCampaignDetailsPageId: (state) => {
            const pages = state.activeSurvey != null ? state.activeSurvey.pages : [];

            for (const page of pages) {
                for (const component of page.components) {
                    if(component.type==='MailCampaignDetailsComponent') {
                        return page.extname
                    }
                }
            }

            return pages.extname // fallback to first page
        },

        getTranslation: (state) => (key) => {
            if (state.activeSurvey != null) {
                const t = state.activeSurvey.localeText[key]
                if (t) {
                    return t
                }
                return key
            }
            return 'no locale loaded'
        },

        getLocale: (state) => {
            return (state.userIdentity !=null) ? state.userIdentity.locale : "unknown_locale";
        },

        getInterviewCount: (state) => {
            return (state.segmentedDrillPath!==null) ? state.segmentedDrillPath.nInterviews : ""
        },

        getTrendTitle: (state) => {
            return (state.segmentedDrillPath!==null) ? state.segmentedDrillPath.trendTitle : ""
        },

        getTrendDetails: (state) => {
            return (state.segmentedDrillPath!==null) ? state.segmentedDrillPath.trendDetails : ""
        },

        getFilterTerm: (state) => {
            return (state.filterTerm && state.filterTerm.length>0) ? state.filterTerm : "none";
        },

        isUserAdmin: (state) => {
            return state.userIdentity && state.userIdentity.isUserAdmin
        },

        isUserRoot: (state) => {
            return state.userIdentity && state.userIdentity.isRoot
        },

        forwardToVGSServer: (state) => {
            return state.userIdentity && state.userIdentity.universe==='AudiSverige'
        },

        loginToFinnishUniverse: (state) => {
            const finnishUniverses = ["Laakkonen", "DTFinland", "SEATFinland", "VWFinland", "VWCFinland", "PorscheFinland", "MANFinland", "UsedcarsFI", "AudiFinland", "CrossBrandFinland", "AutoSuni", "AutotaloHartikainen", "SuomenAutohuolto", "Hartikainen", "Bifa", "MarkkuMiettinen", "AutoliikeLahdemaki", "AutoKeha", "Autoverkkokauppa", "SEATEstland"]
            return state.userIdentity && finnishUniverses.includes(state.userIdentity.universe)
        },


        getEnableExcelBenchmarkReport: state => { return state.activeSurvey.enableExcelBenchmarkReport },
        getEnableInterviewsReport: state => { return state.activeSurvey.enableExcelBenchmarkReport },
        getEnableAlertsReport: state => { return state.activeSurvey.enableAlertsReport },
        getEnableActionsReport: state => { return state.activeSurvey.enableActionsReport },
        getEnableSamplesReport: state => { return state.activeSurvey.enableSamplesReport },
        getEnableIndicatorsReport: state => { return state.activeSurvey.enableIndicatorsReport },
        getEnablePDFBenchmarkReport: state => { return state.activeSurvey.enablePDFBenchmarkReport },
        getEnableSingleInterviewReport: state => { return state.activeSurvey.enableSingleInterviewReport },
        getSurveyBugs: state => { return state.activeSurvey.bugs ? state.activeSurvey.bugs : [] },

        getSystemMessage: (state) => {
            return (state.activeSurvey) ? state.activeSurvey.systemMessage : null
        }

    }

})
