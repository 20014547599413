<template>

    <v-container fluid class="wrapper elevation-2" v-bind:style="{ backgroundColor: componentColor }">
        <v-row>
            <v-col cols="12"  class="pb-0">
                <div class="title">
                    {{vardto.title}}
                </div>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <div class="score">
                    {{vardto.formattedValue}}
                </div>
            </v-col>
        </v-row>

        <v-row class="mt-0">
            <v-col class="py-0">
                <div style="height: 12px"></div>
            </v-col>
        </v-row>

        <v-row align="center mb-0">
            <v-col cols="6">

                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <div v-on="on" class="iconnumberleft">

                            <template v-if="showBase">
                                <v-icon  small class="bottomicon, mr-1" color="white">mdi-message-outline</v-icon>
                                <span class="bottomNumbers">{{vardto.formattedBase}}</span>
                            </template>
                            <template v-else>
                                <v-icon color="white" large class="bottomicon">{{ arrowIcon }}</v-icon>
                                <span class="bottomNumbers">{{vardto.formattedDiff}}</span>
                            </template>



                        </div>
                    </template>
                    <span>{{'DIFF_TOOLTIP' | i18n}}</span>
                </v-tooltip>

            </v-col>

            <v-col cols="6" >
                <div class="text-right" v-if="!vardto.hideProgressBar">

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="iconnumberright">

                                <v-icon color="white" small class="bottomicon mr-3">mdi-crosshairs-gps</v-icon>
                                <!--                <v-icon large class="bottomicon">{{ arrowIcon }}</v-icon>-->
                                <span class="bottomNumbers">{{vardto.formattedGoal}}</span>
                            </div>
                        </template>
                        <span>{{'GOAL_TOOLTIP' | i18n}}</span>
                    </v-tooltip>

                </div>
            </v-col>
        </v-row>



    </v-container>

</template>

<script>

export default {
    name: 'MainVariableComponent',

    components: { },

    props: {
        vardto: {}
    },


    computed: {
        showBase() {
            return this.vardto.formattedBase!==null
        },

        arrowIcon: function () {
            if (this.vardto.diffArrow === 1) {
                return 'mdi-menu-up'
            } else if (this.vardto.diffArrow === 2) {
                return 'mdi-menu-down'
            }

            return 'mdi-trending-neutral'
        },

        componentColor: function () {
            if (this.vardto.goalValue && !this.vardto.hideProgressBar) {
                if (this.vardto.value !== undefined) {
                    if (!this.vardto.inverse && this.vardto.value >= this.vardto.goalValue  || (this.vardto.inverse && (this.vardto.value <= this.vardto.goalValue))) {
                        return '#00C896'
                    }
                    return '#BE0000'
                }
                return '#D9D9D9'
            } else {
                return '#0000C8' //'#646464'
            }

        }
    }

}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.wrapper {
    color: white;
    height: 100%;
    border-radius: 5px;
}

div.title {
    /*font-size: 20px !important;*/
    font-size: calc((20/16)*1rem) !important;

    font-family: "Roboto", sans-serif !important;
    font-weight: 500;
    line-height: 20px;
    height: 80px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

div.score {
    padding-left: 10px;
    padding-right: 10px;
    /*font-size: 50px;*/
    font-size: calc((50/16)*1rem);

    font-family: "Roboto", sans-serif !important;
    font-weight: 900;
}


span.bottomNumbers {
    font-family: "Roboto", sans-serif !important;
    font-weight: 900;
    /*font-size: 16px;*/
    font-size: calc((16/16)*1rem);

}

div.iconnumberleft {
    display: flex;
    align-items: center;
}

div.iconnumberright {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 8px;
}



</style>
