import backend from '@/services/backend'

export default {
    downloadStateEnum: Object.freeze({
        none: {
            name: 'none',
            description: 'No report selected for download',
            downloading: false,
            preparingReport: false,
            downloadReady: false,
            allowNew: true
        },
        downloadQueued: {
            name: 'downloadQueued',
            description: 'Report being prepared for download',
            downloading: true,
            preparingReport: true,
            downloadReady: false,
            allowNew: false
        },
        downloadReady: {
            name: 'downloadReady',
            description: 'Report generated and is ready for download',
            downloading: true,
            preparingReport: false,
            downloadReady: true,
            allowNew: false
        },
        downloadCompleted: {
            name: 'downloadReady',
            description: 'Report downloaded',
            downloading: false,
            preparingReport: false,
            downloadReady: true,
            allowNew: true
        }
    }),

    downloadInitiated(authToken, store, translator, surveyName, reportUUID) {
        // console.log('Report download initiated')
        store.dispatch('registerDownloadInitiated', { surveyName: surveyName, reportUUID: reportUUID});
        this.singlePollReportStatus(authToken, store, translator, surveyName, reportUUID)
    },

    singlePollReportStatus(authToken, store, translator, surveyName, reportUUID) {
        // console.log('Polling report status')
        this.getReportStatus(authToken, surveyName, reportUUID).then((response) => {
            // console.log(response.data)
            const result = response.data

            const position = result.position && result.position > 0 ? result.position : null
            const status = result.status

            if (status === 'FINISHED') {
                store.dispatch('registerDownloadReady', { surveyName: surveyName, reportUUID: reportUUID})
                store.commit('showSnackbar', 'Report ready for download')
                //store.commit('showSnackbar', translator('REPORT_READY_SNACK'))
            } else {
                store.dispatch('registerDownloadStatus', { surveyName: surveyName, reportUUID: reportUUID, position: position})
                setTimeout(() => {
                    this.singlePollReportStatus(authToken, store, translator, surveyName, reportUUID)
                }, 1000)
            }
        })
    },

    indicatorsReportURL(authToken, surveyName, drillPath) {
        return backend.defaults.baseURL + '/vuejs/reports/indicators/' + authToken + '/' + surveyName + '/' + drillPath
    },

    rankingListReportURL(authToken, surveyName, drillPath) {
        return backend.defaults.baseURL + '/vuejs/reports/rankinglist/' + authToken + '/' + surveyName + '/' + drillPath
    },

    createRankingReportTask(authToken, surveyName, drillPath) {
//    console.log('rankingReportTask: ' + authToken + ' surveyName:' + surveyName + ' drillPath:' + drillPath)
        return backend.get('vuejs/reports/rankingreport/' + surveyName + '/' + drillPath, {headers: {Authorization: authToken}})
    },

    createInterviewsReportTask(authToken, surveyName, drillPath) {
//    console.log('rankingReportTask: ' + authToken + ' surveyName:' + surveyName + ' drillPath:' + drillPath)
        return backend.get('vuejs/reports/interviewsreport/' + surveyName + '/' + drillPath, {headers: {Authorization: authToken}})
    },


    createAlertsReportTask(authToken, surveyName, drillPath) {
//    console.log('alertsReportTask: ' + authToken + ' surveyName:' + surveyName + ' drillPath:' + drillPath)
        return backend.get('vuejs/reports/alertsreport/' + surveyName + '/' + drillPath, {headers: {Authorization: authToken}})
    },

    createActionsReportTask(authToken, surveyName, drillPath) {
        return backend.get('vuejs/reports/actionsreport/' + surveyName + '/' + drillPath, {headers: {Authorization: authToken}})
    },

    createSamplesReportTask(authToken, surveyName, drillPath, sampleId) {
//    console.log('samplesReportTask: ' + authToken + ' surveyName:' + surveyName + ' drillPath:' + drillPath)

        const idQueryParam = sampleId ? "?sampleId=" + sampleId : "";

        return backend.get('vuejs/reports/samplesreport/' + surveyName + '/' + drillPath + idQueryParam, {headers: {Authorization: authToken}})
    },

    createSampleTableReportTask(authToken, surveyName, drillPath, filterTerm, configId, additionalFilterPaths) {
        const filterQueryParam = filterTerm ? "?filterterm="+filterTerm : "";

        return backend({ method: 'post', url: 'vuejs/reports/sampletablereport/' + surveyName + '/' + configId + '/' + drillPath + filterQueryParam, data: additionalFilterPaths,  headers: { Authorization: authToken } })

        // return backend.get('vuejs/reports/sampletablereport/' + surveyName + '/' + configId + '/' + drillPath + filterQueryParam, {headers: {Authorization: authToken}})
    },


    createReportTask(authToken, reportName, surveyName, drillPath) {
        return backend.get('vuejs/reports/createreport/' + reportName + '/' + surveyName + '/' + drillPath, {headers: {Authorization: authToken}})
    },

    createIndicatorsReportTask(authToken, surveyName, drillPath) {
        return backend.get('vuejs/reports/indicatorsreport/' + surveyName + '/' + drillPath, {headers: {Authorization: authToken}})
    },

    getReportStatus(authToken, surveyName, uuid) {
//    console.log('getReportStatus: ' + authToken + ' uuid:' + uuid)
        return backend.get('vuejs/reports/status/' + surveyName + '/' + uuid, {headers: {Authorization: authToken}})
    },

    taskReportDownloadURL(reportId) {
        const rootURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : ''
        return rootURL + '/rest/vuejs/download/taskreport/' + reportId;
    },

//       return ReportsService.overviewReportDownloadURL(this.getAuthToken, "uid=" + this.userIdentity.userId + "&t=prp" + "&translate=false" + "&dr=" + this.getCompatibleFilteredDrillPath)
    overviewReportDownloadURL(authToken, surveyName, drillPath) {
        const rootURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : ''
        return rootURL + '/rest/vuejs/reports/overview/' + authToken + '/' + surveyName + '/' + drillPath
    },

    individualInterviewReportDownloadURL(authToken, interviewIds) {
        const rootURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : ''
        return rootURL + '/rest/vuejs/reports/interview/' + authToken + '/' + interviewIds
    },

    mailCampaignReportDownloadURL(authToken, surveyName, campaignId) {
        const rootURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : ''
        return rootURL + '/rest/vuejs/reports/mailcampaign/' + authToken + '/' + surveyName + '/' + campaignId
    },

    individualFlexInterviewReportDownloadURL(authToken, surveyName, interviewId, drillPath) {
        const rootURL = (process.env.NODE_ENV === 'development') ? 'http://localhost:8080' : ''
        return rootURL + '/rest/vuejs/reports/flexinterview/' + authToken + '/' + surveyName + '/' + interviewId + '/' + drillPath
    }

}
