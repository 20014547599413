<template>

  <v-container fluid>
    <ConfirmDialog ref="confirm" />

    <SampleEditorDialog ref="sampleEditor"/>

    <v-bottom-sheet v-if="showNewMailAction" :retain-focus="false" hide-overlay persistent v-model="bottomSheet" transition="none">
      <div style="background-color: #ffffffcc; display: flex; flex-direction: column; padding-top: 2em; padding-bottom: 2em;">

        <div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">

          <div style="margin-left: 116px">

            <div style="font-size: calc((30/16)*1rem); color: black">
              {{ tableData.totalNumberRows }}
            </div>
            <div style="font-weight: bold">
              {{'MAIL_CAMPAIGN_CUSTOMERS_SELECTED' | i18n}}
            </div>
          </div>

          <div style="margin-right: 116px">
            <v-btn color="primary" @click="onStartCampaignClicked">{{'MAIL_CAMPAIGN_START' | i18n}}</v-btn>
          </div>

          </div>
      </div>

    </v-bottom-sheet>

    <v-row v-if="showActionRow">
      <v-col>
        <CopyActionDialog v-for="(copyAction, idx) in componentData.config.copyActions" :config-id="componentData.config.id" :copy-action="copyAction" :key="idx"/>
        <v-btn v-if="componentData.config.createActions.length>0 && isUserRoot" @click="onCreateClicked" color="primary">{{'CREATE' | i18n}}</v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <RankingTable v-if='tableData.headers'
                      v-on:view-sample="onViewSample"
                      v-on:view-interview="onViewInterview"
                      v-on:request-data="onRequestData"
                      :tableheaders="tableData.headers"
                      :tabledata="tableData.rows"
                      :tablefooter="tableData.footer"
                      :leaf-rows="true"
                      :total-rows="tableData.totalNumberRows"
                      :items-per-page="itemsPerPage"
                      :items-per-page-options="itemsPerPageOptions"
                      :loading="tableData.loading"
                      :table-options="tableData.tableOptions"
                      :download-button="true"
                      :additional-filters="additionalFilterPaths.map(namedPath => namedPath.name)"
                      :show-download="true"
                      @download="downloadReport"/>
      </v-col>
    </v-row>

    <v-row justify="end">
      <v-col cols="1">
        <v-btn v-if="showDeleteButton" @click="onDeleteAll">delete all!!</v-btn>
      </v-col>
    </v-row>



  </v-container>
</template>


<script>
import _ from 'lodash'
import {mapGetters, mapState} from 'vuex'
import RankingTable from "../../../ranking/RankingTable";
import SamplesService from "../../../../services/SamplesService";
import PageNavigator from "../../../../pagenavigator"
import CopyActionDialog from "@/views/pages/pagecomponents/sampletable/CopyActionDialog";
// import MailActionDialog from "@/views/pages/pagecomponents/sampletable/MailActionDialog";
import SampleEditorDialog from "@/components/SampleEditorDialog";
import ReportsService from "@/services/ReportsService";
import ConfirmDialog from "@/components/ConfirmDialog.vue";


export default {
  name: 'SampleTable',

  components: {
    ConfirmDialog,
    SampleEditorDialog,
    // MailActionDialog,
    CopyActionDialog,
    RankingTable
  },

  props: {
    componentData: Object
    // samplePageName: String
  },

  data: () => ({
    bottomSheet: true,

    tableData: {
      headers: null,
      rows: null,
      footer: null,
      rankingVar: null,
      leafRows: null,
      totalNumberRows: null,
      loading: false,
      tableOptions: {
        page: 1,
        sortBy: [],
        sortDesc: []
      }

    },
    canViewCompletedSamples: false,
    dataRequestDTO: null,
    componentDrillPath: null,
    additionalFilterPaths: []  // list of named paths
  }),

  methods: {
    onStartCampaignClicked() {
      PageNavigator.navigateToCreateMailCampaignPage(this, this.activeSurvey.extName, this.getFilteredDrillPath, this.getMailAction.extname, this.$route.params.pageId );
    },


    onCreateClicked() {
      SamplesService.createSample(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath).then((response) => {
        PageNavigator.navigateToSamplePage(this, this.activeSurvey.extName, this.$route.params.pageId, response.data)
      })
    },

    onDrillToNamedPath: function (namedPath) {
      if(namedPath.entityId) {
        this.$router.push({ name: 'sample', params: { surveyExtName: this.activeSurvey.extName, fromPage: 'peter', sampleId: namedPath.entityId } })
      } else {
        this.$store.commit('setDrillPath', namedPath.path)
      }
    },

    replaceCurrentRoute() {
      this.$router.replace( {
        name: 'samples',
        params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath },
        query: { page: this.tableData.tableOptions.page, sortBy: this.tableData.tableOptions.sortBy, sortDesc: this.tableData.tableOptions.sortDesc }
      })
    },

    onViewSample: function (interviewId) {
      if (this.enableSampleEditor) {
        this.openSampleEditor(interviewId)
      } else {
        PageNavigator.navigateToSamplePage(this, this.activeSurvey.extName, this.$route.params.pageId, interviewId)
      }

    },

    onViewInterview: function (interviewId) {
      const pageId = this.$route.params.pageId

      if (this.enableSampleEditor) {
        this.openSampleEditor(interviewId)
      } else if( this.canViewCompletedSamples || (this.componentData.config && this.componentData.config.sampleView)   ) {
        PageNavigator.navigateToSamplePage(this, this.activeSurvey.extName, pageId, interviewId)
      } else {
        this.$router.push({ name: 'interview', params: { surveyExtName: this.activeSurvey.extName, interviewId: interviewId } })
      }
    },


    initialFetchData() {
      // console.log("Inital fetch")
      this.tableData.loading = true

      const sortBy = this.componentData.config.sortBy ? this.componentData.config.sortBy : "eventdate"
      const sortDesc = this.componentData.config.sortDesc ? this.componentData.config.sortDesc : true

      SamplesService.fetchSamplesListData(this.getAuthToken, this.activeSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, 0, 50, sortBy, sortDesc, this.filterTerm).then((response) => {
        this.$store.commit('setSegmentedDrillPath', response.data.segmentedDrillPath)

        const sampleData = response.data.rankingData

        this.tableData.headers = sampleData.headers
        this.tableData.rows = sampleData.rows
        this.tableData.footer = sampleData.footer
        this.tableData.rankingVar = sampleData.rankingVar
        this.tableData.leafRows = sampleData.leafRows
        this.tableData.totalNumberRows = sampleData.totalNumberRows

        this.tableData.loading = false

        this.canViewCompletedSamples = response.data.viewCompletedSamples
      })

      this.componentDrillPath = null;
    },

    onRequestData: function (dataRequestDTO) {
      this.dataRequestDTO = dataRequestDTO

      let filterTerm = dataRequestDTO.filter

      if(!filterTerm) {
        filterTerm = this.getFilterTerm
      }

      this.tableData.tableOptions.page = dataRequestDTO.pageNumber //1+(dataRequestDTO.rowFrom/50)
      this.tableData.tableOptions.sortBy = [dataRequestDTO.sortVar]
      this.tableData.tableOptions.sortDesc = [ dataRequestDTO.sortDesc ]

      const fetchPath = this.componentDrillPath ? this.componentDrillPath : this.getFilteredDrillPath

      const paths = this.additionalFilterPaths.map(namedPath => namedPath.path)

      SamplesService.fetchSamplesListData(this.getAuthToken, this.activeSurvey.extName, this.componentData.config.id, fetchPath, dataRequestDTO.rowFrom, dataRequestDTO.rowTo, dataRequestDTO.sortVar, dataRequestDTO.sortDesc, filterTerm, paths).then((response) => {
        this.$store.commit('setSegmentedDrillPath', response.data.segmentedDrillPath)
        const sampleData = response.data.rankingData

        this.tableData.rows = sampleData.rows
        this.tableData.loading = false

        this.canViewCompletedSamples = response.data.viewCompletedSamples
      })

    },

    async openSampleEditor(sampleId) {
      if (await this.$refs.sampleEditor.open(this.componentData.config.id, sampleId)) {
        this.onRequestData(this.dataRequestDTO)
      } else {
        this.onRequestData(this.dataRequestDTO)
      }
    },


    loadDataByPath(drillPath) {

      this.tableData.loading = true

      const sortBy = this.componentData.config.sortBy ? this.componentData.config.sortBy : "eventdate"
      const sortDesc = this.componentData.config.sortDesc ? this.componentData.config.sortDesc : true

      const paths = this.additionalFilterPaths.map(namedPath => namedPath.path)

      SamplesService.fetchSamplesListData(this.getAuthToken, this.activeSurvey.extName, this.componentData.config.id, drillPath, 0, 50, sortBy, sortDesc, this.filterTerm, paths).then((response) => {

        const sampleData = response.data.rankingData

        this.tableData.headers = sampleData.headers
        this.tableData.rows = sampleData.rows
        this.tableData.footer = sampleData.footer
        this.tableData.rankingVar = sampleData.rankingVar
        this.tableData.leafRows = sampleData.leafRows
        this.tableData.totalNumberRows = sampleData.totalNumberRows

        this.tableData.loading = false

        this.canViewCompletedSamples = response.data.viewCompletedSamples
      })

    },

    downloadReport() {
      const fetchPath = this.componentDrillPath ? this.componentDrillPath : this.getFilteredDrillPath

      const paths = this.additionalFilterPaths.map(namedPath => namedPath.path)

      ReportsService.createSampleTableReportTask(this.getAuthToken, this.activeSurvey.extName, fetchPath, this.filterTerm, this.componentData.config.id, paths).then((response) => {
        ReportsService.downloadInitiated(this.getAuthToken, this.$store, this.$t, this.activeSurvey.extName, response.data.taskUUID)
        this.$store.commit('showSnackbar', this.$t("PREPARING_REPORT_SNACK"))
      })

    },

      async onDeleteAll() {
        if (await this.$refs.confirm.open("Confirm", "PERMANANTLY delete samples from database?")) {

          const paths = this.additionalFilterPaths.map(namedPath => namedPath.path)

          SamplesService.fetchSamplesListData(this.getAuthToken, this.activeSurvey.extName, this.componentData.config.id, this.getFilteredDrillPath, 0, 50, null, true, this.filterTerm, paths, true).then((response) => {
            this.$store.commit('showSnackbar', "Deleted " + response.data + " samples")
            this.initialFetchData()
          })
        }
      },



    },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList', 'searchTerm', 'filterTerm', 'globalEvent']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

    showDeleteButton() {
      return this.isUserRoot && this.componentData.config.useCore
    },

    hasAdditionalFilters() {
      return this.additionalFilterPaths.length>0
    },

    showNewMailAction() {
      return this.componentData.config.mailActions.length>0
    },

    getMailAction() {
      return this.componentData.config.mailActions[0]
    },

    page: function () {
      return _.find(this.activeSurvey.pages, { extname: 'samples' })
    },

    enableSampleEditor() {
      return this.componentData.config.sampleEditor
    },

    itemsPerPage() {
      return this.componentData.config.rowsPerPage;
    },

    itemsPerPageOptions() {
      return this.componentData.config.rowsPerPageOptions;
    },

    showDownloadButton() {
      return this.componentData.config.enableDownload
    },

    showActionRow() {
      return this.componentData.config.copyActions && this.componentData.config.copyActions.length>0
          || (this.componentData.config.createActions.length>0 && this.isUserRoot)
    }




},

  watch: {

    globalEvent: function (newEvent) {
      if(newEvent!==null) {
        const match = this.componentData.config.eventSources.some(e => e===newEvent.source)

        if(match) {
          this.additionalFilterPaths = [ ... newEvent.data]
          this.loadDataByPath( this.getFilteredDrillPath )
        }

      }
    },

    $route: {
      handler() {
        let surveyExtName = this.$route.params.surveyExtName
        if(surveyExtName !== this.activeSurvey.extName) {
          this.$store.commit('selectSurvey', surveyExtName)
        }

        let drillPath = this.$route.params.drillPath
        this.$store.commit('setFilteredDrillPath', drillPath)
        this.$store.commit('setFilterTerm', "")
        this.$store.commit("setSearchSuggestions", [])

        const pageNumber = (this.$route.query.page) ? this.$route.query.page : 1

        let sortBy = this.componentData.config.sortBy ? this.componentData.config.sortBy : "eventdate"
        let sortDesc = this.componentData.config.sortDesc ? this.componentData.config.sortDesc : true

        sortBy = (this.$route.query.sortBy) ? this.$route.query.sortBy : sortBy
        sortDesc = (this.$route.query.sortDesc) ? (this.$route.query.sortDesc === 'true') : sortDesc

        this.tableData.tableOptions.page = pageNumber
        this.tableData.tableOptions.sortBy = [sortBy]
        this.tableData.tableOptions.sortDesc = [sortDesc]

        this.initialFetchData();
      },
      immediate: true
    },

    getFilteredDrillPath: function (newPath, oldPath) {
      if (newPath && (newPath !== oldPath)) {
        this.$store.commit('setFilterTerm', "")
        this.$router.push({ name: 'samples', params: { surveyExtName: this.activeSurvey.extName, drillPath: this.getFilteredDrillPath } })
      } else {
//          console.log("They were the same: " + this.getFilteredDrillPath)
      }
    },

    filterTerm: function (newTerm, oldTerm) {
      if(newTerm !== oldTerm) {
        this.loadDataByPath(this.componentDrillPath ? this.componentDrillPath : this.getFilteredDrillPath);
      }
    },

    searchTerm: function (newTerm, oldTerm) {
      if (newTerm && (newTerm !== oldTerm)) {
        SamplesService.fetchSampleListSuggestions(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath, newTerm).then((response) => {
          const suggestionsDTO = response.data
          this.$store.commit("setSearchSuggestions", suggestionsDTO.suggestions)
        })
      }
    }

  }



}
</script>
