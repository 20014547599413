<template>
  <div class="cellContainer">
    <a :href="linkData.url">{{linkData.text}}</a>
  </div>
</template>

<script>
export default {
  name: 'LinkCell',

  data: () => ({
    colors: [],
    visibilities: []
  }),

  props: {
    linkData: Object
  },

  methods: {
    ballVisibility: function (no) {
      return this.visibilities[no]
    }
  },

}

</script>

<style scoped>
div.cellContainer {
  text-align: right;
  white-space: nowrap;
}

</style>
