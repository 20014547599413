<template>
  <v-card outlined>
    <v-card-title>{{title}}</v-card-title>

    <v-card-actions>
      <v-text-field
          v-model="filterText"
          label="Filter"
          append-icon="mdi-filter-outline"/>
    </v-card-actions>


    <v-simple-table>
      <thead>
      <tr>
        <th class="text-left">Type</th>
        <th class="text-left">Metainterviews</th>
        <th class="text-left">Description</th>
      </tr>

      </thead>
      <tbody>
      <tr v-for="sblock in filteredScriptBlocks" :key="sblock.id" @click="onScriptClick(sblock)">
        <td :class="{ disabledRow: sblock.disabled }">{{sblock.scriptType}}</td>
        <td :class="{ disabledRow: sblock.disabled }">{{sblock.metaInterviewExtName}}</td>
        <td :class="{ disabledRow: sblock.disabled }">{{sblock.description}}</td>
      </tr>
      </tbody>
    </v-simple-table>

    <div class="d-flex">
      <v-btn @click="onCreateScriptBlock()" icon>
        <v-icon>mdi-plus-circle-outline</v-icon>
      </v-btn>
    </div>


  </v-card>
</template>

<script>

import {mapGetters} from "vuex";
import ScriptsService from "@/services/ScriptsService";
import PageNavigator from "@/pagenavigator";

export default {
  name: 'ScriptBlockList',

  components: {
  },

  data: () => ({
    scriptBlocks: [],
    filterText: ""
  }),

  props: {
    globalScript: Boolean
  },

  computed: {
    ...mapGetters(['getAuthToken', 'getActiveSurvey', 'getFilteredDrillPath']),

    title() {
      return this.globalScript ? "Global scripts" : "Scripts"
    },

    filteredScriptBlocks() {
      const search = this.filterText.toLowerCase()
      return this.scriptBlocks.filter( sb =>
          (sb.description && sb.description.toLowerCase().indexOf(search)!==-1) ||
          (sb.scriptType && sb.scriptType.toLowerCase().indexOf(search)!==-1) ||
          (sb.metaInterviewExtName && sb.metaInterviewExtName.toLowerCase().indexOf(search)!==-1) )
    }

  },

  created() {
    if(!this.globalScript) {
      ScriptsService.fetchScriptBlocks(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId).then((response) => {
        this.scriptBlocks = response.data;
      })
    } else {
      ScriptsService.fetchGlobalScriptBlocks(this.getAuthToken, this.getActiveSurvey.extName).then((response) => {
        this.scriptBlocks = response.data;
      })
    }
  },

  methods: {
    onCreateScriptBlock() {

      if(this.globalScript) {
        ScriptsService.createGlobalScriptBlock(this.getAuthToken, this.getActiveSurvey.extName).then((response) => {
          this.scriptBlocks.push(response.data)
        })
      } else {
        ScriptsService.createScriptBlock(this.getAuthToken, this.getActiveSurvey.extName, this.$route.params.surveyId).then((response) => {
          this.scriptBlocks.push(response.data)
        })
      }
    },

    onScriptClick(sblock) {
      if(this.globalScript) {
        PageNavigator.navigateToGlobalScriptDetailsPage(this, this.getActiveSurvey.extName, sblock.id)
      } else {
        PageNavigator.navigateToScriptDetailsPage(this, this.getActiveSurvey.extName, this.$route.params.surveyId, sblock.id)
      }
    },



  }


}
</script>

<style scoped>

.normalRow {
  color: black;
}

.disabledRow {
  text-decoration: line-through;
  color: #888888;
}

</style>