<template>
    <v-container fluid class="ma-2" v-if="userEditorData">

        <v-row>
            <v-col cols="12">

                <v-card>
                    <v-form v-model="isValid" ref="form">
                        <v-container fluid>

                            <v-row>
                                <v-col cols="2">
                                    <!--                  <v-text-field v-model="userEditorData.email"  :label="$t('EMAIL')" required :rules="nameRules"/>-->
                                    <v-text-field v-model="userEditorData.keyCloakData.email"  :label="$t('EMAIL')" required :rules="nameRules" @change="onChangeEmail"/>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="userEditorData.keyCloakData.firstName"  label="firstName" required :rules="nameRules"/>
                                </v-col>
                                <v-col cols="2">
                                    <v-text-field v-model="userEditorData.keyCloakData.lastName"  label="lastName" required :rules="nameRules"/>
                                </v-col>
                                <v-col cols="1">
                                    <v-checkbox v-model="userEditorData.keyCloakData.enabled"  label="enabled"/>
                                </v-col>
                                <v-col cols="1">
                                    <v-checkbox v-model="userEditorData.indicators" label="indicators"/>
                                </v-col>
                                <v-col cols="1">
                                    <v-checkbox v-model="userEditorData.invite" label="invite"/>
                                </v-col>
                            </v-row>

<!--                            <v-row justify="end">-->
<!--                                <v-col cols="2">-->
<!--                                    <v-btn text @click="onSubmitClicked" :disabled="!isValid">-->
<!--                                        <template v-if="isNew">{{'CREATE' | i18n}}</template>-->
<!--                                        <template v-else>{{'UPDATE' | i18n}}</template>-->
<!--                                    </v-btn>-->
<!--                                    <v-btn text>{{'CANCEL' | i18n}}</v-btn>-->
<!--                                </v-col>-->
<!--                            </v-row>-->

                            {{validateErrors}}

                        </v-container>

                    </v-form>
                </v-card>
            </v-col>
        </v-row>

        <v-row v-if="fullAccess">
<!--            <v-row >-->
            <v-col>
                <KeyCloakUserComponent v-bind.sync="userEditorData.keyCloakData" @updatekeycloakdata="onUpdateKeyClockData"/>
            </v-col>
        </v-row>

        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>Select brands</v-card-title>
                    <v-card-text>
                        <div style="display: flex; flex-direction: row; gap: 2em;" >
                            <template v-for="(uniUser) in userEditorData.universeUsers" >
                                <v-checkbox v-if="uniUser.userId!==0" :label="uniUser.universeName" :key="uniUser.universeName" input-value="true" value disabled></v-checkbox>
                                <v-checkbox v-else :label="uniUser.universeName" :key="uniUser.universeName" v-model="uniUser.createuser"></v-checkbox>
                            </template>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-row>

            <template v-for="(uniUser) in userEditorData.universeUsers" >
                <UniverseUserComponent v-if="uniUser.userId!==0 || uniUser.createuser"
                                       v-bind.sync="uniUser"
                                       :full-access="fullAccess"
                                       @updateuqf="updateUQF(uniUser, $event)"
                                       @updateglobaluqf="updateGlobalUQF(uniUser, $event)"
                                       @update-channeldata="updateChannelData(uniUser, ...arguments)"
                                       ref="universeUserComponents" :key="uniUser.universeName"/>
            </template>

        </v-row>

        <v-row>
            <v-col cols="2">
                <v-btn text @click="onSubmitClicked" :disabled="!isValid">
                    <template v-if="isNew">{{'CREATE' | i18n}}</template>
                    <template v-else>{{'UPDATE' | i18n}}</template>
                </v-btn>
                <v-btn text @click="onCancelClicked">{{'CANCEL' | i18n}}</v-btn>
            </v-col>
        </v-row>


    </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import UsersService from "../../services/UsersService";
import UniverseUserComponent from "@/views/users/UniverseUserComponent.vue";
import KeyCloakUserComponent from "@/views/users/KeyCloakUserComponent.vue";


export default {
    name: 'UserEditor',

    components: {
        KeyCloakUserComponent,
        UniverseUserComponent
    },

    data: () => ({
        isValid: false,

        userEditorData: null,
        userDetails: null,

        // repeatPassword: '',
        sendNotifications: null,

        rolesList: [],

        nameRules: [
            v => !!v || 'Name is required'
        ],

        roleRules: [
            v => !!v || 'Role is required'
        ],

        validateErrors: null

    }),

    created: function () {
        this.initialFetchData();
    },

    computed: {
        ...mapState(['activeSurvey']),
        ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'isUserRoot']),

        fullAccess() {
            return this.isUserRoot;
        },

        isNew() {
            return this.userEditorData && !this.userEditorData.keyCloakData.userId
        }

    },


    methods: {
        onChangeEmail(email) {

            UsersService.fetchUserEditorData(this.getAuthToken, this.activeSurvey.extName, email).then((response) => {

                UsersService.fetchKeyCloakData(this.getAuthToken, this.activeSurvey.extName, email).then((kcresponse) => {
                    this.userEditorData = response.data
                    this.userEditorData.keyCloakData = kcresponse.data
                })

            })
        },

      updateChannelData(uniUser, channelData, channelName, idx) {
        const surveyChannels = uniUser.notificationChannels[channelName]
        surveyChannels[idx] = channelData
      },

      updateGlobalUQF(uniUser, uqf) {

            for(const survey of uniUser.surveys) {
                for (const userVar of survey.userVariables) {
                    if (userVar.globalName === uqf.globalName) {
                        userVar.value = uqf.value
                    }
                }
            }

        },



        updateUQF(uniUser, uqf) {
            for(const survey of uniUser.surveys) {
                for (const userVar of survey.userVariables) {
                    if (userVar.questionId === uqf.questionId) {
                        userVar.value = uqf.value
                        // console.log("Yes assigned! " + uqf.value)
                    }
                }
            }

        },

        onUpdateKeyClockData() {
            UsersService.fetchKeyCloakData(this.getAuthToken, this.activeSurvey.extName, this.userEditorData.keyCloakData.email).then((response) => {
                this.userEditorData.keyCloakData = response.data
            })
        },

        initialFetchData() {
            UsersService.fetchUserEditorData(this.getAuthToken, this.activeSurvey.extName, this.$route.params.userId).then((response) => {
                this.userEditorData = response.data

                // this.sendNotifications = this.userDetails.userDTO.notifyFlags===0
                //
                // this.rolesList = []
                //
                // for( const [k,v] of this.userDetails.roleNames.entries()) {
                //   this.rolesList.push( { text: v, value: this.userDetails.roleIDs[k] }  )
                // }

            })
        },

        onCancelClicked() {
            this.$router.push({name: 'users', params: {surveyExtName: this.activeSurvey.extName} })
        },

        onSubmitClicked() {
            if (this.$refs.form.validate()) {

                let minimalOneDashUser = false;

                for(const uu of this.userEditorData.universeUsers ) {
                    if(uu.createuser || uu.userId!==0) {
                        minimalOneDashUser = true;
                    }
                }

                if(!minimalOneDashUser) {
                    this.validateErrors = "At least one universe must be associated with user";
                    return;
                }

                let validated = true;

                for (const qcomp of this.$refs.universeUserComponents) {
                    validated &= qcomp.validateMandatory()
                }

                if (!validated) {
                    return
                }

                UsersService.updateUserEditorData(this.getAuthToken, this.activeSurvey.extName, this.userEditorData).then((response) => {
                    let result = response.data
                    if (result.error) {
                        this.$store.commit('showSnackbar', "result.error")
                    } else {
                        this.$store.commit('showSnackbar', "user saved")
                        this.$router.push({name: 'users', params: {surveyExtName: this.activeSurvey.extName}})
                    }
                })
            }
        },

        // updateSurveyData(idx, surveyData) {
        //   this.userDetails.userQuestionFilterMap[idx] = surveyData;
        //
        //   // console.log("**")
        //   // console.log(surveyData)
        //
        //   // for(const uqf of surveyData) {
        //   //   if(uqf.globalName) {
        //   //     this.assignGlobalVariables(uqf);
        //   //   }
        //   // }
        //
        //   // const uqf = this.userDetails.userQuestionFilterMap[idx];
        //
        //
        // },

        // assignGlobalVariables(assigningUQF) {
        //   // console.log("assignGlobalVariables");
        //   // console.log(assigningUQF)
        //   // console.log(this.userDetails.userQuestionFilterMap)
        //
        //   for (let property in this.userDetails.userQuestionFilterMap) {
        //     const uqfMap = this.userDetails.userQuestionFilterMap[property]
        //     // console.log(uqfMap)
        //
        //     for(const uqf of uqfMap) {
        //       // console.log(uqf)
        //       if(assigningUQF.globalName === uqf.globalName) {
        //         // console.log("Assigning to " + property + " " + assigningUQF.globalName + " value: " + assigningUQF.value)
        //         uqf.value = assigningUQF.value
        //       }
        //
        //     }
        //
        //
        //   }
        //
        //   // for(let idx uqfMap of this.userDetails.userQuestionFilterMap) {
        //   //   console.log(uqfMap)
        //   // }
        //
        // },
        //
        // updateChannelData(idx, channelName, channelData) {
        //   const surveyChannels = this.userDetails.channelDTOs[idx]
        //   surveyChannels[channelName] = channelData
        // }

    },


}
</script>
