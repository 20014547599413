<template>
  <v-container fluid pa-0>
    <v-row>
      <v-col class="pt-0 pb-0">
        <DrillPathComponent @path-selected="onDrillToNamedPath" :leaf="true" :show-filter="true" :show-download="true" :ranking-list-report="enableRankingListReport"/>
      </v-col>
    </v-row>

    <v-row class="elevation-2">
      <v-col class="pt-0 pb-0">
        <FilterBarComponent v-if="activeSurvey"  :filter-sets="[activeSurvey.standardFilterSet, activeSurvey.timeFilterSet]" :show-info="true"/>
<!--        <FilterBarComponent v-if="activeSurvey && activeSurvey.timeFilterSet && activeSurvey.standardFilterSet"  :filter-sets="[activeSurvey.standardFilterSet, activeSurvey.timeFilterSet]" :show-info="true"/>-->
      </v-col>
    </v-row>

    <CampaignDialog v-if="getCampaigns.length>0"/>

    <v-row v-if="page && page.components">
      <v-col v-for="(comp, index) in page.components" :key="index" :cols="comp.config.size">
        <PageComponent :component-data="comp"/>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import _ from 'lodash'
import PageComponent from "./PageComponent";
import DrillPathComponent from "../../components/DrillPathComponent";
import FilterBarComponent from "../../components/FilterBarComponent";
import ResultsService from "../../services/ResultsService";
import CampaignDialog from "@/components/CampaignDialog";

export default {
  name: 'GenericPage',

  components: {
    CampaignDialog,
    PageComponent, DrillPathComponent, FilterBarComponent
  },

  data () {
    return {
      pageId: null,
      dialog: true
    }
  },

  methods: {
    onDrillToNamedPath: function (namedPath) {
      if(!namedPath.entityId) {
        this.$store.commit('setDrillPath', namedPath.path)
      }
    },

    loadSegmentedPath() {
      ResultsService.fetchSegmentedDrillPath(this.getAuthToken, this.activeSurvey.extName, this.getFilteredDrillPath).then((response) => {
        this.$store.commit('setSegmentedDrillPath', response.data)
        this.$store.commit('setNumberOpenAlerts', response.data.nOpenAlerts)
      })
    },

  },

  computed: {
    ...mapState(['activeSurvey', 'activeDrillPath', 'sampleList']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath', 'getCampaigns']),

    enableRankingListReport: function() {
      return this.activeSurvey.enableRankingListReport /*&& this.tableData && !this.tableData.leafRows */
    },

    page: function () {
      let pageId = this.$route.params.pageId
      return _.find(this.activeSurvey.pages, { extname: pageId })
    }
  },

  watch: {
    $route: {
      handler() {
        let drillPath = this.$route.params.drillPath
//        console.log("router changed! - setting drillpath: " + drillPath)
        this.$store.commit('setFilteredDrillPath', drillPath)
        this.loadSegmentedPath();
      },
      immediate: true
    },

    getFilteredDrillPath: function (newPath, oldPath) {
      if (newPath && (newPath !== oldPath)) {
        const pageParams = { pageId: this.$route.params.pageId, surveyExtName: this.activeSurvey.extName, drillPath: newPath }
        this.$router.push({ name: 'page', params: pageParams })
      }
    }

  },


}
</script>

<style scoped>
</style>
