<template>
  <KeyCloakLogin v-if="showKeyCloakLogin"></KeyCloakLogin>
  <LegacyLogin v-else-if="showLegacyLogin"></LegacyLogin>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import KeyCloakLogin from "@/views/KeyCloakLogin.vue";
import LegacyLogin from "@/views/LegacyLogin.vue";
import Clientconfig from "@/clientconfig";
import backend from "@/services/backend";

export default {
  name: 'login',

  components: {LegacyLogin, KeyCloakLogin },

  computed: {
    ...mapState(['userIdentity', 'activeSurvey', 'activeDrillPath']),
    ...mapGetters(['getFilteredDrillPath', 'forwardToVGSServer', 'loginToFinnishUniverse', 'getAuthToken']),
  },

  data: () => ({
    showKeyCloakLogin: false,
    showLegacyLogin: false,
  }),

  created() {
    if (!Clientconfig.isLoaded()) {
      backend.get('/config/client').then((response) => {
        let clientConfigDTO = response.data
        Clientconfig.setConfig(clientConfigDTO)
        this.showKeyCloakLogin = Clientconfig.isKeyCloakMode()
        this.showLegacyLogin = !Clientconfig.isKeyCloakMode()
      });
    } else {
      this.showKeyCloakLogin = Clientconfig.isKeyCloakMode()
      this.showLegacyLogin = !Clientconfig.isKeyCloakMode()
    }
  }

}
</script>
