<template>
  <v-container fluid pa-0>

    <v-row class="pa-1">
      <v-col>
        <v-autocomplete :disabled="!isUserEditable" @change="onChange" :label="uqfmodel.title ? uqfmodel.title : uqfmodel.variable" v-model="selectedItems" :items="suggestions" multiple chips deletable-chips :delimiters="[';']" :search-input.sync="search" required :rules="inputRules">

          <template v-slot:item="data">
            <template v-if="typeof data.item !== 'object'">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>

              <template v-if="data.item.unknown">
                <v-list-item-content v-if="data.item.unknown">
                  <v-list-item-title v-html="data.item.text + ' ' + $t('ADD_ANYWAY')"></v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon >mdi-plus</v-icon>
                  </v-list-item-icon>
              </template>
              <template v-else>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.text"></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </template>

        </v-autocomplete>
      </v-col>
    </v-row>

  </v-container>

</template>


<script>
import {mapGetters, mapState} from 'vuex'
import UsersService from "../../services/UsersService";

export default {
  name: 'NewUserQuestionFilterComponent',

  components: {
  },

  props: {
    uqfmodel: Object,
    isUserEditable: Boolean
  },

  data: () => ({
    search: null,
    selectedItems: [],
    suggestions: [],

    valueRules: [
       v => {
        if(this.uqfmodel.required && v.length===0) {
          return 'Value is required'
        }  else {
          return true
        }
       }
    ],

  }),

  created: function () {
    if(this.uqfmodel.value && this.uqfmodel.value.length>0) {
      this.selectedItems = this.uqfmodel.value.split(";")
      this.suggestions = this.uqfmodel.value.split(";")
    }
  },

  computed: {
    ...mapState(['activeSurvey']),
    ...mapGetters(['getAuthToken', 'getFilteredDrillPath']),

    inputRules() {
      return [
        v => {
          // console.log(this.uqfmodel.required)
          if(this.uqfmodel.required && v.length===0) {
            return 'Value is required'
          }  else {
            return true
          }
        }
      ]
    }


  },

  methods: {
    onChange() {
      this.uqfmodel.changed = true;
    },

  },

  watch: {

    uqfmodel: {
      handler(newuqfmodel){
        if(newuqfmodel.value && newuqfmodel.value.length>0) {
          this.selectedItems = newuqfmodel.value.split(";")
          this.suggestions = newuqfmodel.value.split(";")
        }
      },
      deep: true
    },

    search(searchTerm) {
      if(searchTerm!==null && searchTerm!=="") {
        UsersService.getQuestionFilterSuggestions(this.getAuthToken, this.uqfmodel.surveyName, this.uqfmodel.questionId, searchTerm).then((response) => {
          this.suggestions = [...this.selectedItems]
          this.suggestions.push(...response.data)
        });
      }
    },

    selectedItems(items) {
      const resultString = items.join(";")
      this.$emit('update-filters', resultString)
    }

  },

}
</script>
